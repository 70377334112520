


/* --------------- chat-banner styles --------------- */
.chat-banner.style-3{
    background-color: var(--color-blue2);
    position: relative;
    &::after{
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 60%;
        background-image: url(../../images/system/metrix.png);
        background-size: 80%;
        background-repeat: repeat;
        background-position: bottom;
        opacity: 0.08;
        -webkit-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(1003%) contrast(103%);
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(1003%) contrast(103%);
    }
    .info{
        h3{
            font-size: 27px;
            color: #fff;
            text-transform: capitalize;
            span{
                font-weight: 400;
                font-style: italic;
                position: relative;
                &::before{
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 100%;
                    width: 240px;
                    height: 35px;
                    background-image: url(../../images/system/info_h1_line1.png);
                    background-size: 240px;
                    background-repeat: no-repeat;
                    background-position: left;
                }
            }
        }
    }
    .bttns{
        .btn{
        }
    }
}