


/* --------------- footer styles --------------- */

footer.style-1 {
    background-color: #000;
    position: relative;
    .foot_l{
        position: absolute;
        left: 0;
        bottom: 0;
        pointer-events: none;
    }
    .foot_r{
        position: absolute;
        right: 0;
        top: 0;
        pointer-events: none;
    }
    .content {
        padding: 90px 0 70px;
        position: relative;
        z-index: 5;
    }
    p,
    a,
    li {
        font-size: 13px;
        color: #aaa7a7;
    }
    a:hover {
        color: var(--color-main);
    }
    .text {
        color: #fff;
        font-size: 14px;
    }
    .foot_info {
        li {
            margin: 20px 0;
            i {
                color: var(--color-main);
                font-size: 16px;
            }
        }
    }
    .social_icons {
        margin-top: 10px;
        display: flex;
        a {
            width: 33px;
            height: 33px;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: #2c2c2c;
            color: #aaa7a7;
            margin: 5px;
            &:hover {
                background: var(--color-main-grad);
                color: #fff;
            }
        }
    }
    .links {
        display: flex;
        justify-content: center;
        li {
            margin: 8px 0;
        }
    }
    .link_title {
        color: #fff;
        margin: 10px 0 15px;
    }
    .foot_subscribe {
        input {
            background: #1f1f1f;
            color: #fff;
            font-size: 12px;
            border: 0;
            &::placeholder {
                color: #fff;
            }
        }
    }
    .foot {
        position: relative;
        z-index: 5;
        text-align: center;
        padding: 30px 0;
        border-top: 1px solid #fff3;
    }
}

// --------- footer style-2 ----------
footer.style-2{
    background-color: #010040;
}

// --------- footer style-3 ----------
footer.style-3{
    position: relative;
    padding-top: 100px;
    overflow: hidden;
    .items{
        .title{
            font-weight: bold;
            margin-bottom: 30px;
        }
        .socail-icons{
            margin-top: 30px;
            a{
                &:hover{
                    background-color: var(--color-blue2) !important;
                    color: #fff !important;
                }
            }
        }
        ul{
            li{
                margin: 5px 0;
                a{
                    font-size: 12px;
                    &:hover{
                        color: var(--color-blue2);
                    }
                }
            }
        }
    }
    .foot{
        padding: 35px 0 30px;
        position: relative;
        margin-top: 80px;
        .logo{
            width: 120px;
        }
        .testi_lines{
            position: absolute;
            top: -10px;
        }
    }
    .contact_globe{
        position: absolute;
        height: 150%;
        max-height: none;
        width: 60%;
        top: -25%;
        left: 20%;
        opacity: 0.15;
        -webkit-animation: rotate-center 100s linear infinite both;
                animation: rotate-center 100s linear infinite both;
    }
}

// --------- footer style-4 ----------
footer.style-4{
    position: relative;
    background-color: #f0eff5;
    margin-top: 200px;
    .wave{
        position: absolute;
        left: -3%;
        bottom: 95%;
        width: 106%;
        max-width: unset;
        height: 240px;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: top;
        object-position: top;
        z-index: 20;
    }
    .container{
        position: relative;
        z-index: 25;
    }
    .foot{
        border-bottom: 1px solid #9999;
        padding: 30px 0;
        .links{
            display: flex;
            justify-content: center;
            li{
                a{
                    font-size: 12px;
                    margin: 0 15px;
                    font-weight: bold;
                    &:hover,
                    &.active{
                        color: var(--color-blue4);
                    }
                }
            }
        }
    }
    .copywrite{
        padding: 35px 0;
    }
}

// --------- footer style-5 ----------
footer.style-5{
    position: relative;
    padding-top: 100px;
    overflow: hidden;
    .items{
        .title{
            font-weight: bold;
            margin-bottom: 30px;
            color: #000;
            line-height: 1;
        }
        .socail-icons{
            margin-top: 30px;
            a{
                &:hover{
                    background-color: var(--color-blue5) !important;
                    color: #fff !important;
                }
            }
        }
        ul{
            li{
                margin: 5px 0;
                a{
                    font-size: 12px;
                    &:hover{
                        color: var(--color-blue5);
                    }
                }
            }
        }
    }
    .foot{
        padding: 35px 0 30px;
        position: relative;
        margin-top: 80px;
        .logo{
            width: 120px;
        }
    }
}

// --------- footer style-6 ----------
footer.style-6{
    position: relative;
    padding-top: 100px;
    overflow: hidden;
    .items{
        .title{
            font-weight: bold;
            margin-bottom: 30px;
            color: #000;
        }
        .socail-icons{
            margin-top: 30px;
            a{
                &:hover{
                    background-color: var(--color-blue6) !important;
                    color: #fff !important;
                }
            }
        }
        ul{
            li{
                margin: 5px 0;
                a{
                    font-size: 12px;
                    &:hover{
                        color: var(--color-blue6);
                    }
                }
            }
        }
    }

    .form{
        .form-group{
            position: relative;
            .icon{
                position: absolute;
                top: 12px;
                left: 15px;
            }
            input{
                width: 100%;
                border: 1px solid #9993;
                border-radius: 30px;
                min-height: 45px;
                font-size: 12px;
                padding: 0 40px;
            }
            button{
                position: absolute;
                right: 5px;
                top: 5px;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                background-color: var(--color-blue6);
                border: 0;
                color: #fff;
            }
        }
    }

    .foot{
        padding: 35px 0 30px;
        position: relative;
        margin-top: 80px;
        .logo{
            width: 120px;
        }
    }
    
}