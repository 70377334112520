


/* --------------- blog styles --------------- */

.blog.style-1 {
    .content {
        .blog_slider {
            position: relative;
            .swiper-button-next,
            .swiper-button-prev {
                width: 35px;
                height: 35px;
            }
            .swiper-button-next,
            .swiper-container-rtl .swiper-button-prev {
                right: 120px;
                top: -63px;
            }
            .swiper-button-prev,
            .swiper-container-rtl .swiper-button-next {
                right: 160px;
                left: auto;
                top: -63px;
            }
        }
        .blog_box {
            position: relative;
            border-radius: 10px;
            overflow: hidden;
            .img {
                height: 350px;
                position: relative;
                &::after {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0.5;
                    background-image: linear-gradient(to top, #000, transparent);
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all 1s ease;
                }
            }
            .tags {
                position: absolute;
                top: 20px;
                left: 20px;
                z-index: 10;
                a {
                    font-size: 11px;
                    padding: 3px 7px;
                    border-radius: 4px;
                    background: var(--color-main-grad);
                    color: #fff;
                    text-transform: uppercase;
                }
            }
            .info {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 20px;
                z-index: 5;
                h6 {
                    color: #fff;
                    margin-bottom: 10px;
                    border-bottom: 2px solid transparent;
                    display: inline-block;
                    line-height: 1.3;
                    transition: all 0.3s ease;
                    &:hover {
                        a {
                            color: var(--color-main);
                        }
                    }
                }
                .auther {
                    display: flex;
                    span {
                        padding: 0 10px;
                        color: #fff;
                        font-size: 12px;
                        &:first-of-type {
                            padding-left: 0;
                            border-right: 1px solid #9993;
                        }
                        .auther-img {
                            width: 15px;
                            height: 15px;
                            border-radius: 50%;
                            object-fit: cover;
                            margin-right: 3px;
                        }
                        i {
                            font-size: 11px;
                            margin-right: 4px;
                        }
                    }
                }
                .text {
                    margin-top: 20px;
                    font-size: 11px;
                    color: #fff;
                    display: none;
                }
            }
            &:hover {
                .img {
                    img {
                        transform: scale(1.3) rotate(-10deg);
                    }
                }
                .info {
                    h6 {
                        border-color: #fff9;
                    }
                }
            }
        }
    }
}


// ----------- blog style-2 -------
.blog.style-2{
    // background: linear-gradient(to bottom, #157aa6 , #010049);
    background-image: url(../../images/system/num_back.png);
    background-size: cover;
    position: relative;
    // .num_shape{
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     width: 100%;
    //     height: 80%;
    //     opacity: 0.5;
    // }
    .card-img-top{
        height: 300px;
        width: 100%;
        object-fit: cover;
        border-radius: 6px;
    }
}


// ----------- blog style-3 -------
.blog.style-3{
    background-color: #f0eff5;
    position: relative;
    .v_lines{
        position: absolute;
        top: 100px;
        left: 50%;
        height: calc(100% - 340px);
        transform: translateX(-50%);
    }
    .card{
        .img{
            height: 120px;
        }
        .card-body{
            .date{
                span{
                    font-size: 11px;
                    letter-spacing: 1px;
                }
            }
            .card-title{
                font-size: 18px;
                font-weight: bold;
                max-width: 85%;
                margin-top: 10px;
                min-height: 45px;
            }
            .small{
                font-size: 10px;
            }
        }
        
    }
    .client-logos{
        .img{
            img{
                filter: grayscale(1);
                opacity: 0.6;
            }
            &:hover{
                img{
                    filter: grayscale(0);
                    opacity: 1;
                }
            }
        }
    }
}

// ----------- blog style-6 -------
.blog.style-6{
    .content{
        display: flex;
        justify-content: space-between;
        .blog-card.style-6{
            width: 30%;
            position: relative;
            &.card-center{
                &::after{
                    position: absolute;
                    content: "";
                    right: -28px;
                    top: 0;
                    width: 1px;
                    height: 100%;
                    background-color: #9994;
                }
                &::before{
                    position: absolute;
                    content: "";
                    left: -28px;
                    top: 0;
                    width: 1px;
                    height: 100%;
                    background-color: #9994;
                }
            }
        }
    }
    a:hover{
        color: var(--color-blue6);
    }
}
.blog-card.style-6{
    position: relative;
    height: 100%;
    .img{
        height: 230px;
        border-radius: 20px;
        overflow: hidden;
    }
    .info{
        padding: 30px 0;
        .blog-title{
            color: #000;
            font-size: 22px;
            margin: 10px 0 10px;
        }
        .text{
            color: #666;
            font-size: 13px;
        }
    }
}