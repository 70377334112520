


/* --------------- single-project style-5 --------------- */
.single-project.style-5{
    .about-app.style-5{
        h1{
            font-size: 55px;
        }
        .about-cards{
            .about-card{
                text-align: center;
                padding: 15px;
                small{
                    font-size: 11px;
                    color: #999;
                    margin-bottom: 10px;
                    text-transform: uppercase;
                }
                h6{
                    color: #000;
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 1.6;
                }
            }
        }
        .img-content{
            .img{
                position: relative;
                .main-img{
                    position: relative;
                    z-index: 5;
                }
                .circle{
                    position: absolute;
                    z-index: 0;
                    left: 0;
                    top: 15%;
                    height: 70%;
                    object-fit: contain;
                    object-position: center;
                    -webkit-animation: rotate-center 100s linear infinite both reverse;
                            animation: rotate-center 100s linear infinite both reverse;
                }
                .bubbls{
                    position: absolute;
                    z-index: 0;
                    left: 0;
                    top: 0;
                    width: 100%;
                    object-fit: contain;
                    object-position: center;
                    -webkit-animation: rotate-center 100s linear infinite both;
                            animation: rotate-center 100s linear infinite both;
                }
            }
        }
    }
    .screenshots{
        position: relative;
        &::after{
            position: absolute;
            content: "";
            background-image: url(../../images/system/about_s4_wave.png);
            background-repeat: repeat-x;
            width: 100%;
            height: 30px;
            top: -1px;
            left: 0;
            z-index: 10;
            transform: rotateX(180deg);
        }
    }

    .share{
        .social-icon{
            width: 35px;
            height: 35px;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: #f3f7fe;
            color: #666;
            font-size: 12px;
            margin: 20px 3px 0;
            &:hover{
                background-color: var(--color-blue5);
                color: #fff;
            }

            &.hover-blue4{
                &:hover{
                    background-color: var(--color-blue4);
                    color: #fff;
                }
            }
        }
    }

    .projects{
        .project-card.style-6:hover .info .title{
            color: var(--color-blue5);
        }

        .project-card.style-6 .info small{
            color: var(--color-blue5);
        }
    }

    .slider-style-6 .swiper-button-next:hover, .slider-style-6 .swiper-button-prev:hover{
        background-color: var(--color-blue5);
    }
}
