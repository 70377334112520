


/* --------------- top navbar styles --------------- */

.top-navbar.style-1 {
  background: var(--color-main-grad);
  position: relative;
  z-index: 9999;
  .top-links {
      .text {
          font-size: 11px;
          strong {
              margin: 0 10px;
          }
          .fa-bullhorn {
              background-color: #002ec1;
              padding: 15px;
          }
      }
  }
  .r-side {
      display: flex;
      justify-content: flex-end;
      .socail-icons {
          border-right: 1px solid #fff4;
          padding-right: 10px;
          margin-right: 14px;
          a {
              color: #fff;
              margin: 0 5px;
              font-size: 12px;
          }
      }
      .dropdown {
          color: #fff;
          font-size: 11px;
          img {
              width: 15px;
              height: 15px;
              border-radius: 50%;
              object-fit: cover;
          }
      }
  }
}

/* --------------- top navbar style-4 --------------- */
.top-navbar.style-4{
    background-image: url(../../images/system/top_nav.png);
    background-size: cover;
    background-position: top;
    padding: 10px 15px 50px;
    text-align: center;
}

/* --------------- top navbar style-5 --------------- */
.top-navbar.style-5{
    padding: 10px 15px;
    text-align: center;
    background-color: var(--color-blue5);
}


/* --------------- top navbar styles --------------- */
/* --------------- top navbar styles --------------- */
