


/* --------------- buttons styles --------------- */
.butn,
.btn {
    padding: 12px 30px;
    position: relative;
    overflow: hidden;
    text-align: center;
    font-weight: 500;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    span {
        // color: #fff;
        font-size: 14px;
        position: relative;
        z-index: 2;
        text-transform: capitalize;
    }

    small{
        font-size: 12px;
        position: relative;
        z-index: 2;
        text-transform: capitalize;
    }

    &.butn-gard {
        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            width: 150%;
            height: 100%;
            z-index: 1;
            background: linear-gradient(to right, #0c3df4 10%, #02b5ff 45%, #02b5ff, #0c3df4);
            transition: all 0.3s ease;
        }
        &:hover {
            &::before {
                left: -50%;
            }
        }
    }

    &.light_brdr_butn {
        // border: 1px solid #fff;
        // background: transparent;
        span {
            // color: #fff;
        }
    }

    &.dark-butn {
        background: #000;
        span,
        small {
            color: #fff;
        }
    }

    &.butn-blue6 {
        background: var(--color-blue6);
        span,
        small {
            color: #fff;
        }
    }

    &.blue5-3Dbutn {
        background: var(--color-blue5);
        box-shadow:0px 4px 0px 0px var(--color-blue4);
        filter: drop-shadow(0 13px 20px #07397235);
        font-size: 12px;
        span,
        small {
            color: #fff;
        }
    }
      

    &.sm-butn {
        padding: 10px 25px;
        span {
            font-size: 12px;
        }
    }
    &:hover{
        background: var(--color-main);
        border-color: transparent !important;
        span{
            color: #fff;
        }
    }

    // ----------

    &.hover-darkBlue{
        &:hover{
            background: var(--color-darkBlue);
            border-color: transparent !important;
            span,
            small{
                color: #fff;
            }
        }
    }

    &.hover-blue2{
        &:hover{
            background: var(--color-blue2);
            border-color: transparent !important;
            span,
            small{
                color: #fff;
            }
        }
    }

    &.hover-blue4{
        &:hover{
            background: var(--color-blue4);
            border-color: transparent !important;
            span,
            small{
                color: #fff;
            }
        }
    }

    &.hover-blue5{
        &:hover{
            background: var(--color-blue5);
            border-color: transparent !important;
            span,
            small{
                color: #fff;
            }
        }
    }

    &.hover-lightBlue{
        &:hover{
            background: var(--color-lightBlue);
            border-color: transparent !important;
            span,
            small{
                color: var(--color-darkBlue);
            }
        }
    }
}


