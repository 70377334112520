


/* --------------- about styles --------------- */

.about.style-1 {
    position: relative;
    .content {
        padding: 65px 120px;
        background: var(--color-main-grad);
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        z-index: 5;
        .about_shap {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 0;
            opacity: 30%;
            pointer-events: none;
        }
        .about_logos {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 40px;
            border-bottom: 1px solid #fff4;
            position: relative;
            z-index: 5;
        }
        .about-info {
            padding: 60px 0;
            position: relative;
            z-index: 5;
            .title {
                h3 {
                    color: #fff;
                    font-weight: 600;
                    font-size: 30px;
                }
                small {
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 11px;
                    margin-top: 35px;
                    display: block;
                }
            }
            .info {
                h6 {
                    color: #fff;
                    font-size: 14px;
                    line-height: 1.6;
                    margin-bottom: 25px;
                }
                p {
                    font-size: 14px;
                    color: #c7e9ff;
                }
                .butn {
                    margin-top: 50px;
                }
            }
        }
        .about-numbers {
            position: relative;
            z-index: 5;
            .num-item {
                display: flex;
                align-items: center;
                .num {
                    color: #fff;
                    font-size: 32px;
                    font-weight: bold;
                    flex-shrink: 0;
                    margin-right: 15px;
                    min-width: 70px;
                    text-align: center;
                    i {
                        font-size: 21px;
                    }
                }
                .inf {
                    color: #fff;
                }
            }
        }
    }
}

// ----------- about style 3 -----------
.about.style-3{
    position: relative;
    .top-content{
        min-height: 510px;
        margin-bottom: 100px;
        padding-top: 20px;
    }
    .info{
        h5{
            font-size: 19px;
            line-height: 28px;
            font-style: italic;
            margin-bottom: 25px;
        }
        .text{
            color: #666;
        }
        ul{
            li{
                font-size: 14px;
                font-weight: 600;
                margin: 15px 0;
                i{
                    color: var(--color-blue2);
                }
            }
        }
    }
    .img-left{
        position: absolute;
        left: 0;
        top: 190px;
        max-width: 50%;
        .info-circle{
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50% , -40%);
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 230px;
            height: 230px;
            background-color: #4a00e1;
            color: #fff;
            border-radius: 50%;
            overflow: hidden;
            outline: 10px solid #fff;
            // -webkit-animation: scale_up_down 2s ease-in-out infinite alternate both;
	        //         animation: scale_up_down 2s ease-in-out infinite alternate both;
            h2{
                font-size: 56px;
            }
            small{
                font-size: 11px;
                text-transform: uppercase;
            }
            &:nth-of-type(2){
                width: 200px;
                height: 200px;
                left: auto;
                right: 0;
                top: 50%;
                transform: translate(-14%, -57%);
                z-index: 5;
                h2{
                    font-size: 50px;
                }
            }
            &:nth-of-type(3){
                width: 150px;
                height: 150px;
                left: auto;
                right: 0;
                top: 50%;
                transform: translate(-88%, 32%);
                background-color: #8d3dfa;
                z-index: 0;
                h2{
                    font-size: 37px;
                }
            }
            &:nth-of-type(4){
                width: 180px;
                height: 180px;
                left: 0;
                top: 50%;
                transform: translate(20%, -20%);
                background-color: #00bcf2;
                z-index: 0;
                h2{
                    font-size: 46px;
                }
            }
        }
        
    }

    .btm-content{
        position: relative;
        min-height: 510px;
        padding-top: 50px;
        .img-right{
            position: absolute;
            right: 0;
            top: 0;
            max-width: 60%;
        }
    }
}

// ----------- about style 4 -----------
.about.style-4{
    overflow: hidden;
    position: relative;
    .top-wave{
        position: absolute;
        top: -2px;
        width: 100%;
    }
    .bottom-wave{
        position: absolute;
        bottom: -2px;
        width: 100%;
        transform: rotate(180deg);
    }
    .content{
        position: relative;
        padding: 60px 0;
        ul{
            li{
                h6{
                    font-size: 15px;
                }
            }
        }
        &.frs-content,
        &.trd-content{
            .lines{
                position: absolute;
                left: 0;
                top: -80px;
                width: 40%;
                max-width: unset;
                max-height: unset;
            }
            .bubble{
                position: absolute;
                left: 0;
                top: 0;
                width: 50%;
                max-width: unset;
                max-height: unset;
                -webkit-animation: rotate-center 100s linear infinite both;
                        animation: rotate-center 100s linear infinite both;
            }
        }
        &.sec-content{
            .bubble2{
                position: absolute;
                right: 0;
                top: 0;
                width: 50%;
                max-width: unset;
                max-height: unset;
                -webkit-animation: rotate-center 100s linear infinite both;
                        animation: rotate-center 100s linear infinite both;
            }
        }
    }


    .btn-img{
        text-align: initial;
        display: inline-flex;
        background-color: #f4f2fb;
        align-items: center;
        .icon{
            flex-shrink: 0;
            padding-right: 15px;
            margin-right: 15px;
            border-right: 1px solid #0001;
            width: 45px;
        }
        .inf{
            height: max-content;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            small{
                font-size: 10px;
                color: #666;
            }
            h6{
                font-size: 12px;
                color: #000;
                font-weight: bold;
                line-height: 1.5;
            }
        }
    }

    .integration{
        position: relative;
        .intg-back{
            position: absolute;
            left: 0;
            bottom: 19%;
            width: 100%;
            max-height: unset;
            object-fit: cover;
        }
        .content{
            display: flex;
            justify-content: space-between;
            position: relative;
            .img{
                -webkit-animation: slide_up_down 1.7s ease-in-out infinite alternate both;
	                    animation: slide_up_down 1.7s ease-in-out infinite alternate both;
                &:nth-of-type(1){
                    animation-delay: -0.5s;
                }
                &:nth-of-type(2){
                    animation-delay: -1s;
                }
                &:nth-of-type(3){
                    animation-delay: -1.5s;
                }
                &:nth-of-type(4){
                    animation-delay: -2s;
                }
                &:nth-of-type(5){
                    animation-delay: -2.5s;
                }
            }
        }
    }
}

// ----------- about style 5 -----------
.about.style-5{
    .content{
        padding: 50px 0;
        p{
            font-size: 12.5px;
            color: #777;
            line-height: 2;
        }
        .line-links{
            margin-top: 35px;
            a{
                position: relative;
                color: #000;
                font-size: 15px;
                font-weight: bold;
                border-bottom: 1px solid #9993;
                padding: 13px 0;
                display: block;
                width: 100%;
                &:last-of-type{
                    border-bottom: 0;
                }
                &::after{
                    position: absolute;
                    content: "";
                    left: 0;
                    bottom: 0;
                    height: 1px;
                    width: 0%;
                    background-color: var(--color-blue5);
                    transition: all 0.3s ease;
                }
                &:hover{
                    &::after{
                        width: 100%;
                    }
                }
            }
        }
        .list-icon {
            margin-top: 40px;
            li {
                display: flex;
                margin-bottom: 15px;
                .icon {
                    flex-shrink: 0;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background: var(--color-blue5);
                    color: #fff;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 15px;
                }
                h6 {
                    font-size: 15px;
                    font-weight: 700;
                }
            }
        }
        .img{
            position: relative;
            text-align: right;
            &.main-img1{
                .img-body{
                    position: relative;
                    z-index: 2;
                    top: 50px;
                    -webkit-animation: slide_up_down 3s ease-in-out infinite alternate both;
                            animation: slide_up_down 3s ease-in-out infinite alternate both;
                }
                img{
                    position: absolute;
                    object-fit: contain;
                    &.sm-circle{
                        right: -50px;
                        top: 20%;
                        z-index: 1;
                        -webkit-animation: rotate-center 20s linear infinite both reverse;
                                animation: rotate-center 20s linear infinite both reverse;
                    }
                    &.lg-circle{
                        left: 0;
                        top: 0;
                        z-index: 1;
                        -webkit-animation: rotate-center 50s linear infinite both;
                                animation: rotate-center 50s linear infinite both;
                    }
                    &.card1{
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 100%;
                        // height: 100%;
                        z-index: 3;
                        -webkit-animation: scale_up_down 10s ease-in-out infinite alternate both;
                                animation: scale_up_down 10s ease-in-out infinite alternate both;
                    }
                    &.card2{
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 2;
                        -webkit-animation: scale_up_down 7s ease-in-out infinite alternate both;
                                animation: scale_up_down 7s ease-in-out infinite alternate both;
                        transition-delay: -5s;
                    }
                }
            }
            &.main-img2{
                .img-body{
                    position: relative;
                    z-index: 2;
                    -webkit-animation: slide_up_down 3s ease-in-out infinite alternate both;
                            animation: slide_up_down 3s ease-in-out infinite alternate both;
                }
                img{
                    position: absolute;
                    &:nth-of-type(1){
                        right: 0;
                        top: 0;
                        width: 100%;
                        z-index: 1;
                        -webkit-animation: rotate-center 70s linear infinite both;
                                animation: rotate-center 70s linear infinite both;
                    }
                }
            }
            &.main-img3{
                position: relative;
                .img-body{
                    position: relative;
                    z-index: 1;
                    max-height: 500px;
                    object-fit: contain;
                    -webkit-animation: rotate-center 100s linear infinite both;
                                animation: rotate-center 100s linear infinite both;
                }
                img{
                    position: absolute;
                    right: 0;
                    top: 20%;
                    width: 100%;
                    &:nth-of-type(2){
                        z-index: 3;
                        -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
                                animation: slide_up_down 2s ease-in-out infinite alternate both;
                    }
                    &:nth-of-type(3){
                        z-index: 1;
                        -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
                                animation: slide_up_down 2s ease-in-out infinite alternate both;
                        animation-delay: 1s;
                    }
                    &:nth-of-type(4){
                        z-index: 2;
                        -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
                                animation: slide_up_down 2s ease-in-out infinite alternate both;
                        animation-delay: 2s;
                    }
                    &:nth-of-type(5){
                        z-index: 4;
                        -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
                                animation: slide_up_down 2s ease-in-out infinite alternate both;
                        animation-delay: 3s;
                    }
                }
            }
        }
    }
}

// ----------- about style 6 -----------
.about.style-6{
    position: relative;
    .content{
        border-bottom: 1px solid #9994;
        .info{
            .text{
                color: #666;
                font-size: 15px;
            }
            .vid-btn{
                font-size: 11px;
                font-weight: bold;
                display: inline-flex;
                align-items: center;
                i{
                    width: 40px;
                    height: 40px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    border: 1px solid var(--color-blue6);
                    color: var(--color-blue6);
                    font-size: 21px;
                    margin-right: 5px;
                }
            }
        }
    }
    .bubbles{
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 50%;
        object-fit: contain;
    }
}