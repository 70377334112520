


/* --------------- screenshots style-4 --------------- */
.screenshots.style-4{
    background-color: #f0eff5;
    overflow: hidden;
    padding-top: 100px;
    position: relative;
    height: 700px;
    &::after{
        position: absolute;
        content: "";
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 30px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        background-color: #fff;
        z-index: 10;
    }
    .screenshots-slider{
        position: absolute;
        top: 110px;
        width: calc(100% + 120px);
        left: -60px;
        .img{
            margin: 0 auto;
            height: 420px;
            width: 190px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 20px;
            }
        }
    }
    .mob-hand{
        pointer-events: none;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-33%);
        height: 600px;
        z-index: 10;
    }
}


