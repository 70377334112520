/* --------------- clients style-4 --------------- */
.clients.style-4 {
    background-color: #f0eff5;
    position: relative;
    z-index: 25;
    .client-logos {
        border-bottom: 1px solid #9994;
        text-align: center;
        .img {
            img {
                filter: grayscale(1);
                opacity: 0.6;
            }
            &:hover {
                img {
                    filter: grayscale(0);
                    opacity: 1;
                }
            }
        }
                h6 {
                    font-weight: bold;
                    font-size: 15px;
                    line-height: 23px;
                }
    }
}

/* --------------- clients style 5 --------------- */
.clients.style-5 {
    .content {
        .img {
            padding: 30px;
            text-align: center;
            border-radius: 20px;
            border: 1px solid #9994;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 5px;
            height: 150px;
            &.img-card{
                width: 19%;
            }
        }
    }

    .swiper-wrapper {
        -webkit-transition-timing-function: linear !important;
        transition-timing-function: linear !important;
        position: relative;
    }
}

/* --------------- clients styles --------------- */
