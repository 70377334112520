


/* --------------- services page styles --------------- */
.shop-page{
    .filter{
        .filter-card{
            padding: 20px 15px;
            border-radius: 10px;
            background-color: #f0f4f8;
            text-transform: capitalize;
            .card-title{
                font-size: 15px;
                color: #000;
                font-weight: 800;
                margin-bottom: 15px;
            }
            .form-check-label{
                cursor: pointer;
            }
            .cat-link{
                color: #000;
                font-size: 11px;
                font-weight: 600;
            }
            .category-checkRadio{
                padding: 0;
                line-height: 1;
                .form-check-input{
                    display: none;
                }

                .form-check-input:checked ~ .form-check-label{
                    color: var(--color-blue5);
                }
            }

            .accordion{
                .accordion-button{
                    color: #000;
                    font-size: 11px;
                    font-weight: 600;
                    padding: 0;
                    background: transparent;
                    margin-bottom: 10px;
                    box-shadow: none;
                }
    
                .accordion-button::after{
                    background-size: 0.8rem;
                    background-position: center;
                }
    
                .accordion-button:not(.collapsed){
                    color: var(--color-blue5);
                }
            }

            .filter-card-item{
                padding-bottom: 15px;
                border-bottom: 1px solid #9994;
                margin-bottom: 20px;
                .sub-tilte{
                    font-size: 11px;
                    color: #000;
                    font-weight: 800;
                    position: relative;
                    cursor: pointer;
                    margin-bottom: 15px;
                }
                .filter-card-body{
                    .filter-card-scroll{
                        max-height: 130px;
                        overflow-x: hidden;
                        overflow-y: auto;
                        &::-webkit-scrollbar {
                            height: 10px;
                            width: 5px;
                            background: #dfdfdf;
                            border-radius: 10px;
                        }
                        
                        &::-webkit-scrollbar-thumb {
                            background: var(--color-blue5);
                            -webkit-border-radius: 0;
                            border-radius: 10px;
                        }
                        
                        &::-webkit-scrollbar-corner {
                            background: var(--color-blue5);
                            border-radius: 10px;
                        }
                    }
                }

                .search-group{
                    position: relative;
                    margin-bottom: 25px;
                    input{
                        width: 100%;
                        background-color: #fff;
                        border: 0;
                        border-radius: 5px;
                        padding: 10px;
                        font-size: 11px;
                        min-height: 40px;
                        padding-inline-end: 20px;
                    }
                    button{
                        position: absolute;
                        right: 8px;
                        top: 10px;
                        font-size: 13px;
                        border: 0;
                        background: transparent;
                    }
                }
            }

            .category-checkBox{
                font-size: 11px;
                color: #666;
                text-transform: capitalize;
            }

            .slider-range-content{
                .amount-input{
                    display: flex;
                    justify-content: space-between;
                    .amount{
                        width: 35%;
                        margin-inline-end: 5%;
                        border-radius: 7px;
                        background-color: #fff;
                        font-size: 12px;
                        overflow: hidden;
                        padding: 6px 10px;
                        small{
                            font-size: 10px;
                            color: #999999;
                        }
                        input{
                            border: 0;
                            width: 100%;
                        }
                    }
                    button{
                        width: 50px;
                        height: 50px;
                        background-color: var(--color-blue5);
                        border: 0;
                        color: #fff;
                        border-radius: 7px;
                        text-transform: capitalize;
                    }
                }
                .ui-slider-horizontal{
                    margin-top: 25px;
                    height: 6px;
                    border: 0;
                    background-color: #dfdfdf;
                    .ui-slider-range{
                        background-color: var(--color-blue5);
                    }
    
                    .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active{
                        border: 0;
                        background: var(--color-blue5);
                        border-radius: 50%;
                        width: 1em;
                        height: 1em;
                    }
                }
            }
        }
    }

    .products-content{
        .top-filter{
            padding: 15px 0;
            .r-side{
                .form{
                    .form-group{
                        display: flex;
                        align-items: center;
                        font-size: 11px;
                        label{
                            color: #999;
                            flex-shrink: 0;
                            margin-inline-end: 15px;
                        }

                        .form-select{
                            border: 0;
                            background-color: #f0f4f8;
                            font-size: 11px;
                            min-height: 35px;
                            border-radius: 12px;
                        }
                    }
                }
                .grid-list-btns{
                    display: flex;
                    justify-content: flex-end;
                    .bttn{
                        width: 35px;
                        height: 35px;
                        font-size: 17px;
                        border-radius: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #f0f4f8;
                        color: #000;
                        margin-inline-start: 10px;
                        cursor: pointer;
                        &:hover,
                        &.active{
                            color: #fff;
                            background-color: var(--color-blue5);
                        }
                    }
                }
            }
        }
        .products{
            .product-card{
                border: 1px solid #ededed;
                padding: 30px 15px 15px;
                position: relative;
                border-radius: 12px;
                padding-bottom: 15px;
                overflow: hidden;
                transition: all 0.4s ease;
                margin-bottom: 24px;

                &:hover{
                    padding-bottom: 60px;
                    .img{
                        img{
                            transform: scale(0.8);
                        }
                    }
                    .info{
                        margin-top: -25px;
                    }
                    .btn{
                        transform: translateY(0);
                        opacity: 1;
                    }
                }

                .img{
                    height: 130px;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        transition: all 0.4s ease;
                        transform-origin: top;
                    }
                }

                .info{
                    margin-top: 20px;
                    min-height: 77px;
                    transition: all 0.4s ease;
                    .category{
                        font-size: 10px;
                        font-weight: bold;
                        text-transform: uppercase;
                        margin-bottom: 7px;
                    }

                    .title{
                        font-size: 12px;
                        text-transform: capitalize;
                        font-weight: 400;
                        margin-bottom: 0;
                    }

                    .stars{
                        display: inline-flex;
                        font-size: 9px;
                        color: #d0d0d0;
                        .active{
                            color: #ffba00;
                        }
                    }

                    .rev{
                        color: #999;
                        font-size: 9px;
                    }

                }

                .price{
                    margin-top: 15px;
                    font-size: 15px;
                    font-weight: bold;
                    .price-sale{
                        color: #f73312;
                    }
                    .old-price{
                        font-size: 11px;
                        color: #999999;
                        text-decoration: line-through !important;
                        margin-inline-start: 5px;
                    }
                }

                .btn{
                    position: absolute;
                    bottom: 15px;
                    left: 15px;
                    width: calc(100% - 30px);
                    background-color: var(--color-blue5);
                    padding: 7px 15px;
                    transform: translateY(70px);
                    opacity: 0;
                    transition: all 0.4s ease;
                    span{
                        font-size: 11px;
                        color: #fff;
                        font-weight: bold;
                    }

                    &:hover{
                        background-color: #000;
                    }
                }

                .label{
                    position: absolute;
                    left: 10px;
                    top: 10px;
                    font-size: 9px;
                    border-radius: 4px;
                    background-color: #f0f0f0;
                    color: #000;
                    padding: 3px 10px;
                    text-transform: uppercase;

                    &.new{
                        background-color: #ffb500;
                    }

                    &.sale-off{
                        background-color: #f73312;
                        color: #fff;
                    }
                }

                .fav-btn{
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    font-size: 14px;
                    color: #ccc;
                    cursor: pointer;

                    &.active{
                        color: #f73312;
                    }
                }
            }

            &.list-view{
                .card-width{
                    width: 50%;
                }

                .product-card{
                    display: flex;
                    .img{
                        flex-shrink: 0;
                        width: 35%;
                        margin-right: 10px;
                    }

                    &:hover{
                        padding-top: 12px;
                        padding-bottom: 35px;
                        .img{
                            img{
                                transform: scale(1);
                            }
                        }
                        .info{
                            margin-top: 25px;
                        }
                    }
                }
            }
        }
    }

    &.style-grad{
        .filter{
            .filter-card{
                .category-checkRadio{
                    .form-check-input:checked ~ .form-check-label{
                        color: var(--color-blue4);
                    }
                }
                .accordion{
                    .accordion-button:not(.collapsed){
                        color: var(--color-blue4);
                    }
                }
                .filter-card-item{
                    .filter-card-body{
                        .filter-card-scroll{
                            &::-webkit-scrollbar-thumb {
                                background: var(--color-blue4);
                            }
                            &::-webkit-scrollbar-corner {
                                background: var(--color-blue4);
                            }
                        }
                    }
                }
                .slider-range-content{
                    .amount-input{
                        button{
                            background-color: var(--color-blue4);
                        }
                    }
                    .ui-slider-horizontal{
                        .ui-slider-range{
                            background-color: var(--color-blue4);
                        }
                        .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active{
                            background: var(--color-blue4);
                        }
                    }
                }
            }
        }
        .products-content{
            .top-filter{
                .r-side{
                    .grid-list-btns{
                        .bttn{
                            &:hover,
                            &.active{
                                background-color: var(--color-blue4);
                            }
                        }
                    }
                }
            }
            .products{
                .product-card{
                    .btn{
                        background-color: var(--color-blue4);
                    }
                }
            }
        }
    }
}

