


/* --------------- testimonials styles --------------- */

.testimonials.style-1 {
    .content {
        position: relative;
        z-index: 5;
        .vid_img {
            position: relative;
            height: 400px;
            min-height: 100%;
            &::before {
                position: absolute;
                content: "";
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to top, #0007, #0001);
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .play_icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background: var(--color-main-grad);
                z-index: 5;
                i {
                    color: #fff;
                    font-size: 28px;
                    margin-left: 3px;
                }
            }
            .img_info {
                position: absolute;
                left: 0;
                bottom: 0;
                padding: 30px;
                width: 100%;
                z-index: 5;
                h4 {
                    color: #fff;
                    font-weight: bold;
                }
                small {
                    color: #fff;
                    display: block;
                    font-size: 11px;
                }
            }
        }
        .info {
            position: relative;
            padding-left: 75px;
            min-height: 100%;
            &::after {
                position: absolute;
                content: "";
                left: 25px;
                top: 0;
                width: 2px;
                height: 100%;
                background-color: #9991;
            }
            .client_card {
                border: 1px solid #9994;
                border-radius: 5px;
                padding: 20px;
                display: flex;
                background-color: #fff;
                .user_img {
                    margin-right: 25px;
                    flex-shrink: 0;
                    img {
                        width: 45px;
                        height: 45px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }
                .inf_content {
                    .rate_stars {
                        margin-bottom: 5px;
                        i {
                            color: #ffb400;
                            font-size: 10px;
                        }
                    }
                    h6 {
                        font-weight: bold;
                        font-size: 13px;
                        margin-bottom: 15px;
                    }
                    p {
                        font-size: 12px;
                        color: #000;
                        text-transform: uppercase;
                        span{
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }
    }
}

// --------- reviews style2 ---------
.reviews.style-2{
    position: relative;
    .rev_l{
        position: absolute;
        left: 0;
        top: 30%;
        width: 20%;
        -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
	        animation: slide_up_down 2s ease-in-out infinite alternate both;
    }
    .rev_r{
        position: absolute;
        right: 0;
        top: 30%;
        transform: translateY(-50%);
        width: 20%;
        -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
	        animation: slide_up_down 2s ease-in-out infinite alternate both;
        animation-delay: 1s;
    }
}

.swiper-slide{
    .reviews_card.style-2{
        opacity: 0;
        transform: scale(0.8) translateY(20px);
        transition: all 1.5s ease;
        transition-delay: 0.3s;
    }
    &.swiper-slide-active{
        .reviews_card.style-2{
            opacity: 1;
            transform: scale(1) translateY(0);
        }
    }
}

.reviews_card.style-2{
    .rev_user{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        outline: 1px solid var(--color-lightBlue);
        outline-offset: 5px;
        margin: 40px auto 25px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .rev_stars{
        i{
            color: greenyellow;
        }
    }
}


// ------------ testimonial style-3 ------------
.testimonials.style-3{
    background-color: #f0eff5;
    margin-top: -30px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 5;
    .testi_lines{
        position: absolute;
        bottom: -10px;
    }
    .testimonial-card.style-3{
        .text{
            font-size: 18px;
            line-height: 28px;
            min-height: 112px;
        }
        p{
            font-size: 13px;
            line-height: 1.1;
        }
        small{
            font-size: 11px;
        }
    }
    .testimonial-slider.style-3{
        position: relative;
        .swiper-button-next{
            top: -65px;
            right: 0;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            &::after{
                font-size: 12px;
            }
            &:hover{
                background: var(--color-blue2);
            }
        }
        .swiper-button-prev{
            top: -65px;
            left: auto;
            right: 40px;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            &::after{
                font-size: 12px;
            }
            &:hover{
                background: var(--color-blue2);
            }
        }
    }
}


// ------------ testimonials style-4 ------------
.testimonials.style-4{
    // overflow: hidden;
    .content{
        border-bottom: 1px solid #9993;
        padding-bottom: 80px;
        .numbs{
            display: flex;
            .num-card{
                .icon{
                    height: 55px;
                    display: inline-block;
                    margin-bottom: 15px;
                }
                h2{
                    color: var(--color-blue4);
                }
                p{
                    font-size: 11px;
                    color: #666;
                    margin-top: 5px;
                }
                &:not(:last-of-type){
                    padding-right: 50px;
                    margin-right: 50px;
                    border-right: 1px solid #9993;
                }
            }
        }
        .play-btn{
            display: flex;
            align-items: center;
            .icon{
                width: 42px;
                height: 42px;
                border: 1px solid #5842bc99;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                i{
                    color: var(--color-blue4);
                    font-size: 16px;
                }
            }
        }
        .stars{
            font-size: 10px;
            color: #fdb900;
            margin-bottom: 5px;
        }
        .testi-cards{
            position: relative;
            padding: 0 5vw;
            .client_card {
                position: relative;
                z-index: 10;
                border-radius: 15px;
                padding: 25px;
                display: flex;
                align-items: center;
                background-color: #fff;
                box-shadow: -4.104px 11.276px 38px 0px rgba(45, 42, 61, 0.1);
                margin: 20px;
                .user_img {
                    margin-right: 25px;
                    flex-shrink: 0;
                    img {
                        width: 90px;
                        height: 90px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }
                .inf_content {
                    .rate_stars {
                        margin-bottom: 5px;
                        i {
                            color: #ffb400;
                            font-size: 10px;
                        }
                    }
                    h6 {
                        font-weight: bold;
                        font-size: 12px;
                        margin-bottom: 15px;
                    }
                    p {
                        font-size: 10px;
                        color: #000;
                        text-transform: uppercase;
                        span{
                            text-transform: capitalize;
                            color: #2E99F5;
                        }
                    }
                }
                &:nth-of-type(2){
                    right: -5vw;
                }
            }
            .testi-globe{
                position: absolute;
                top: -80px;
                left: 0;
                width: 110%;
                max-height: unset;
                max-width: unset;
                opacity: 0.2;
                -webkit-animation: rotate-center 100s linear infinite both;
                        animation: rotate-center 100s linear infinite both;
            }
        }
    }
}

// ------------ testimonials style-5 ------------

.testi-card.style-5{
    display: block;
    padding: 40px 30px;
    background-color: #fff;
    border-radius: 15px;
    text-align: center;
    margin: 0 15px;
    &:hover{
        box-shadow: 0px 30px 60px 0px #0a2b5326;
        .card-title{
            color: var(--color-blue5);
        }
    }
    .stars{
        i{
            color: #27a700;
            font-size: 15px;
        }
    }
    .text{
        font-size: 19px;
        line-height: 1.6;
        color: #000;
        margin-top: 15px;
    }
    .user {
        h6{
            margin: 15px auto 0;
            font-size: 14px;
            font-weight: bold;
        }
        small{
            font-size: 11px;
            color: #666;
        }
    }
}

// ------------ testimonials style-6 ------------
.testimonials.style-6{
    padding: 50px 0 120px;
    .testi-slider.style-6{
        .icon{
            width: 95px;
            height: 95px;
            background-color: var(--color-blue6);
            color: #fff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 60% 40% 40% 40%;
            font-size: 35px;
        }

        .testi-card{
            .text{
                color: #000;
                font-size: 23px;
                line-height: 1.4;
            }
        }

        .swiper-button-next,
        .swiper-button-prev{
            background-color: #f1f2fa;
            width: 40px;
            height: 40px;
            top: 100%;
            margin-top: 40px;
            &:hover{
                background-color: var(--color-blue6);
            }
        }

        .swiper-button-next{
            right: auto;
            left: 50px;
        }
        .swiper-button-prev{
            left: 0;
        }
    }
    .img{
        position: relative;
        margin-top: 50px;
        img{
            position: relative;
            z-index: 5;
        }
        .bubbls{
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            object-fit: contain;
            z-index: 0;
        }
    }
}

