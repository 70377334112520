


/* --------------- section head styles --------------- */

.section-head{
    h6{
        font-size: 13px;
        letter-spacing: 2px;
        margin-bottom: 20px;
    }
}

.section-head.style-3{
    margin-bottom: 50px;
    h3{
        font-size: 27px;
        text-transform: capitalize;
        span{
            font-weight: 400;
            color: var(--color-blue2);
            font-style: italic;
            position: relative;
            &::after{
                position: absolute;
                content: "";
                background-image: url(../../images/system/title_shap1.png);
                left: 0;
                bottom: -15px;
                width: calc(100% + 40px);
                height: 30px;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }
    &.long-shape{
        h3{
            span{
                &::after{
                    background-image: url(../../images/system/title_shap2.png);
                    bottom: -15px;
                }
            }
        }
    }
}

/* --------------- section style-4 --------------- */

.section-head.style-4{
    .title_small{
        font-size: 12px;
        padding: 5px 10px;
        background-color: #f7f4ff;
        border-radius: 5px;
        color: var(--color-blue4);
        margin-bottom: 20px;
    }
    h2{
        font-size: 40px;
        line-height: 1.3;
        color: #000;
        span{
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-image: linear-gradient(to right,  #501e9c 0%,#8169f1 30%,#8169f1 30%,#a44cee 73%,#ff847f 100%);
        }
    }
}

/* --------------- section style-5 --------------- */

.section-head.style-5{
    h2{
        font-size: 45px;
        span{
            color: var(--color-blue5);
            position: relative;
            display: inline-block;
            padding: 0 5px;
            margin: 0 5px;
            &::after{
                position: absolute;
                content: "";
                left: 0;
                bottom: 0;
                width: 100%;
                height: 40%;
                background-color: var(--color-blue5);
                opacity: 8%;
            }
        }
    }
    p{
        color: #666;
        font-size: 15px;
    }
}

/* --------------- section style-6 --------------- */
.section-head.style-6{
    h2{
        color: #000;
        font-size: 45px;
        span{
            position: relative;
            padding: 10px 20px;
            small{
                position: relative;
                z-index: 2;
                font-size: 45px;
                color: #fff;
            }
            &::before{
                position: absolute;
                content: "";
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background-image: url(../../images/system/shap_style_6.png);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                z-index: 1;
            }
        }
    }
}
