


/* --------------- services styles --------------- */

.services.style-1 {
    position: relative;
    .ser_shap_l {
        position: absolute;
        left: 0;
        bottom: 30%;
        z-index: -1;
        transition: all 5s ease;
        // opacity: 0;
        // clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
        // &.animated{
        //     opacity: 1;
        //     clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        // }
    }
    .ser_shap_r {
        position: absolute;
        right: 0;
        top: 25%;
        // z-index: -1;
        // transition: all 5s ease;
        // opacity: 0;
        // clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
        // &.animated{
        //     opacity: 1;
        //     clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        // }
    }
    .service-box {
        position: relative;
        z-index: 5;
        padding: 30px;
        border: 1px solid #9993;
        border-radius: 8px;
        display: block;
        background-color: #fff;
        transition: all 0.3s ease;
        h5 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 35px;
            a {
                color: #000;
                font-weight: bold;
            }
            .num {
                color: var(--color-main);
                font-size: 12px;
            }
        }
        .icon {
            margin-bottom: 35px;
            img {
                width: 60px;
                height: 60px;
                object-fit: cover;
            }
        }
        .info {
            .text {
                color: #666;
                margin-bottom: 20px;
                font-size: 13px;
            }
            .tags {
                a {
                    font-size: 11px;
                    padding: 3px 7px;
                    border-radius: 4px;
                    background-color: #eef4f8;
                    margin-top: 10px;
                }
            }
        }
        &:hover {
            border-color: transparent;
            box-shadow: 0px 54px 115px 0px rgba(52, 60, 66, 0.25);
            h5 {
                a {
                    color: var(--color-main);
                }
            }
        }
    }
}

// -------- tags --------
.tags.style-2 a {
    font-size: 11px;
    padding: 3px 7px;
    border-radius: 4px;
    background-color: #131172;
    margin: 3px;
}

.services_projects{
    background-image: url(../../images/system/services_work.png);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;

    .swiper-button-next, .swiper-button-prev{
        width: 50px;
        height: 50px;
        transition: all 0.3s ease;
        &:hover{
            background: var(--color-lightBlue) !important;
        }
    }
}

.swiper-slide-duplicate-prev{
    .service-box {
        border: 0 !important;
    }
}

// ---------- services style-3 -----------
.services.style-3{
    background-color: #f0eff5;
    .service-card.style-3{
        padding: 30px;
        background-color: #fff;
        border-radius: 12px;
        height: 100%;
        .icon{
            margin-bottom: 60px;
            img{
                width: 125px;
                height: 120px;
                object-fit: contain;
            }
        }
        .info{
            .title{
                font-size: 18px;
                color: #000;
                text-transform: capitalize;
                margin-bottom: 20px;
                font-weight: bold;
            }
            .text{
                font-size: 13px;
                color: #666;
            }
            a{
                margin-top: 30px;
                font-weight: bold;
                font-size: 10px;
                text-transform: uppercase;
                &:hover{
                    color: var(--color-blue2);
                }
            }
        }
    }
}

// ---------- services style-6 -----------
.services-slider.style-6{
    .swiper-container{
        padding-bottom: 100px;
    }
    .swiper-slide{
        transform: scale(0.9);
        position: relative;
        transition: all 0.4s ease;
        top: 0;
        &.swiper-slide-nth-next-2{
            transform: scale(1);
            top: 100px;
            padding: 0 9px;
        }
        &.swiper-slide-next{
            transform: scale(1);
            top: 50px;
            padding: 0 12px;
        }
        &.swiper-slide-active{
            transform: scale(1.1);
            padding: 0 20px;
            filter: drop-shadow(5px 50px 30px #07397211);
        }
        &.swiper-slide-prev{
            transform: scale(1);
            top: 50px;
            padding: 0 12px;
        }
        &.swiper-slide-nth-prev-2{
            transform: scale(1);
            top: 100px;
            padding: 0 9px;
        }
    }
}
.service-card.style-6{
    text-align: center;
    padding: 30px 8%;
    background-color: #f1f2fa;
    border-radius: 30px;
    overflow: hidden;
    margin: 20px 0;
    display: block;
    color: #000;
    .icon{
        filter: drop-shadow(5px 15px 20px #07397255);
        margin-bottom: 25px;
        img{
            // height: 50px;
            // width: 80px;
            max-height: 100px;
            max-width: 100px;
            object-fit: contain;
        }
    }
    .info{
        h5{
            font-weight: bold;
            font-size: 15px;
            margin-bottom: 15px;
        }
        .text{
            font-size: 11px;
            color: #666;
        }
    }
}