


/* --------------- navbar styles --------------- */
.navbar{
   z-index: 999; 
   .nav-link{
    cursor: pointer;
   }
}

.navbar.nav-scroll {
    background: #fff !important;
    -webkit-box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    padding: 0;
    position: fixed !important;
    top: -100px !important;
    left: 0;
    width: 100%;
    -webkit-transition: -webkit-transform .5s;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    border-radius: 0 !important;
    margin: 0 !important;
}

// ----------
.navbar.style-1 {
    padding: 30px 0;
    .navbar-brand {
        margin-right: 40px;
    }
    .navbar-nav {
        .nav-item {
            .nav-link {
                font-size: 12px;
                color: #000;
                font-weight: 700;
                line-height: 2;
                margin: 0 7px;
                text-transform: capitalize;
                &.active {
                    color: var(--color-main);
                }
            }
        }
    }
    .nav-side {
        display: flex;
        align-items: center;
        .hotline {
            display: flex;
            align-items: center;
            border-right: 1px solid #9993;
            .icon {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                background: var(--color-main-grad);
                color: #fff;
                font-size: 13px;
                flex-shrink: 0;
            }
            .cont {
                h6 {
                    color: #000;
                    font-size: 15px;
                    font-weight: bold;
                }
            }
        }
    }
    .qoute-nav {
        display: flex;
        align-items: center;
        a {
            // margin: 0 10px;
            .cart-num {
                font-size: 9px;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                color: #fff;
                background: var(--color-main-grad);
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

// ---------- navbar style 2 ----------
.navbar.style-2{
    // .container{
    //     border-bottom: 1px solid #fff3;
    // }
    &.nav-scroll{
        background-color: #010049;
    }
    .navbar-brand{
        img{
            width: 155px;
            object-fit: contain;
        }
    }
    .navbar-nav {
        .nav-item {
            .nav-link {
                font-size: 12px;
                color: #fff;
                font-weight: 500;
                line-height: 2;
                margin: 0;
                padding: 30px 30px;
                border-bottom: 2px solid transparent;
                position: relative;
                &::before{
                    position: absolute;
                    content: "";
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 0%;
                    background-color: #fff2;
                    transition: all 0.3s ease;
                }
                &.active,
                &:hover {
                    border-bottom: 2px solid var(--color-lightBlue);
                    &::before{
                        height: 100%;
                    }
                }
            }
        }
    }
    .qoute-nav {
        display: flex;
        align-items: center;
        a {
            color: #fff;
            .cart-num {
                font-size: 9px;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                color: #000;
                background: var(--color-lightBlue);
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .dropdown-menu{
        background: #348CB2;
        border-radius: 0;
        // padding: 0;
        .dropdown-item{
            color: #fff;
            padding: 8px 15px;
            &.active,
            &:active,
            &:hover{
                background-color: #010049;
            }
        }
    }
}

// ---------- navbar style-3  ----------
.navbar.style-3{
    padding: 20px 0;
    &.nav-scroll{
        background-color: #4A00E1;
    }
    .navbar-brand{
        img{
            width: 155px;
            object-fit: contain;
        }
    }

    .navbar-nav{
        .nav-item{
            .nav-link{
                font-size: 11px;
                color: #fff;
                margin: 0 5px;
                padding: 10px 20px;
                border-radius: 30px;
                &.active,
                &:hover{
                    background-color: #0002;
                }
            }
        }
    }

    .nav-side{
        .search-icon{
            width: 35px;
            height: 35px;
            border-radius: 50%;
            border: 1px solid #fff6;
            color: #fff;
            font-size: 12px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }
}

// ---------- navbar style-4  ----------
.navbar.style-4{
    position: relative;
    z-index: 99;
    padding: 20px 15px;
    background-color: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-top: -38px;
    .navbar-brand{
        width: 150px;
        object-fit: contain;
    }
    .navbar-nav{
        .nav-item{
            .nav-link{
                position: relative;
                color: #000;
                font-size: 12px;
                font-weight: bold;
                margin: 0 15px;
                &::before{
                    position: absolute;
                    content: "";
                    top: -33px;
                    left: 50%;
                    transform: translateX(-50%) translateY(-20px);
                    width: 55px;
                    height: 30px;
                    background-image: url(../../images/system/active_s4.png);
                    background-size: contain;
                    background-repeat: no-repeat;
                    opacity: 0;
                    transition: all 0.3s ease;
                }
                &:hover,
                &.active{
                    color: var(--color-blue4);
                    &::before{
                        opacity: 1;
                        transform: translateX(-50%) translateY(0);
                    }
                }
                .hot{
                    position: absolute;
                    font-size: 8px;
                    padding: 2px 3px;
                    border-radius: 3px;
                    top: -10px;
                    right: 0;
                    text-transform: uppercase;
                }
            }
        }
    }

    .nav-side{
        .search-icon{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 1px solid #0002;
            color: #000;
            font-size: 16px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }
}

// ---------- navbar style-5  ----------
.navbar.style-5{
    position: relative;
    z-index: 99;
    padding: 20px 0;
    background-color: #e7f1ff;
    .container-fluid{
        padding: 0 3vw;
    }
    .navbar-brand{
        img{
            max-width: 150px;
            object-fit: contain;
        }
    }
        .nav-item{
            .nav-link{
                position: relative;
                color: #000;
                font-size: 12px;
                font-weight: bold;
                margin: 0 15px;
                text-transform: capitalize;
                &:hover,
                &.active{
                    color: var(--color-blue5);
                }

                .bi{
                    position: relative;
                    bottom: -5px;
                }
            }
        }

    .nav-side{
        .search-icon{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 1px solid #0002;
            color: #000;
            font-size: 16px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }
}

// ---------- navbar style-6  ----------
.navbar.style-6{
    position: absolute;
    z-index: 99;
    padding: 20px 0;
    background-color: #dee0f2;
    width: 100%;
    border-radius: 30px;
    top: 0;
    left: 0;
    .container-fluid{
        padding: 0 3vw;
    }
    .navbar-brand{
        img{
            max-width: 150px;
            object-fit: contain;
        }
    }
        .nav-item{
            .nav-link{
                position: relative;
                color: #000;
                font-size: 12px;
                font-weight: bold;
                margin: 0 15px;
                text-transform: capitalize;
                &::after{
                    position: absolute;
                    content: "";
                    right: -16px;
                    top: 16px;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: #bebccd;
                }
                &:hover,
                &.active{
                    color: var(--color-blue6);
                }
            }
            &:last-of-type{
                .nav-link{
                    &::after{
                        display: none;
                    }
                }
            }
        }
}


