


/* --------------- contact page styles --------------- */
.contact-page.style-5{
    .contact.style-6 .form-group .form-control:focus, .contact.style-6 .form-group .form-select:focus{
        border-color: var(--color-blue5);
    }
    .map{
        iframe{
            width: 100%;
            margin-bottom: -6px;
        }
    }
}


input.invalid-field{
    border:1px solid #DC3545;
}

.error-message{
    color: #DC3545;
    font-size: 10px;
    font-style: italic;
    position: relative;
    left: 20px;
}