/* -------------------- responsive style ------------------------- */

@media screen and (max-width: 1100px) {
    .screenshots.style-4 .screenshots-slider .img{
        margin: 0 auto;
        width: 190px;
    }
}

@media screen and (max-width: 991px) {
    .section-padding {
        padding: 70px 0;
    }

    .section-head {
        h2 {
            font-size: 25px;
        }
    }

    .top-navbar.style-1 {
        .top-links {
            display: none;
        }
        .r-side {
            justify-content: space-between;
            padding: 10px 0;
        }
    }
    .navbar.style-1 {
        border-bottom: 1px solid #9993;
        .nav-side {
            flex-wrap: wrap;
            .hotline {
                width: 100%;
                margin-bottom: 10px;
                border: 0;
            }
        }
    }
    .portfolio.style-1 {
        .portfolio-slider {
            .swiper-button-next,
            .swiper-button-prev {
                display: none;
            }
        }
    }
    header.style-1 {
        .info {
            margin-bottom: 30px;
            .text {
                width: 100%;
            }
        }
    }

    .about.style-1 {
        .content {
            padding: 30px;
            .num-item {
                padding: 15px;
                border: 1px solid #fff5;
                margin: 5px 0;
            }
        }
    }

    .testimonials.style-1 {
        .content {
            .vid_img {
                min-height: auto;
            }
            .info {
                padding: 0;
                &::after {
                    display: none;
                }
            }
        }
    }

    .choose_us.style-1 {
        .choose-us-img,
        .animated_logos,
        .choose-us-brands,
        .choose-us-bubbles {
            display: none;
        }
        .info {
            .btn {
                margin-top: 30px;
            }
        }
    }

    .team.style-1 .content .team_box {
        width: 100%;
    }

    .blog.style-1 {
        .blog_slider {
            .swiper-button-next,
            .swiper-button-prev {
                position: static;
                margin-top: 40px !important;
                display: inline-flex !important;
            }
        }
    }

    .contact.style-1 {
        .content {
            .contact_info {
                ul {
                    margin: 15px 0;
                }
            }
            .contact_form {
                margin-top: 30px;
            }
        }
    }

    footer.style-1 .links {
        justify-content: flex-start;
    }

    // ---------- home 2 responsive ---------
    .navbar.style-2 {
        padding: 25px 0 !important;
        position: relative !important;
        background: var(--color-main-grad);
        .navbar-collapse {
            margin-top: 30px;
        }
        .container {
            border: 0;
        }
        .qoute-nav{
            .btn{
                margin-left: auto;
                margin-right: 0;
            }
        }
    }

    header.style-2 {
        br {
            display: none;
        }
    }

    .section-head h2 {
        letter-spacing: 3px;
        margin-bottom: 30px;
    }

    .choose_us.style-2 {
        overflow: hidden;
        .choose-numbers {
            margin-top: 50px;
            .num-item {
                width: 100%;
                border: 1px solid #fff3;
                margin: 5px 0;
                padding: 15px;
                flex-wrap: wrap;
                br {
                    display: none;
                }
                .num {
                    width: 100%;
                    text-align: initial;
                }
            }
        }
    }

    .reviews_card.style-2 {
        br {
            display: none;
        }
    }

    .pricing.style-2{
        overflow: hidden;
        .pricing_card.style-2 {
            border: 1px solid var(--color-lightBlue) !important;
            margin: 10px 0 !important;
            border-radius: 0 !important;
        }
        .mt-50{
            margin-top: 30px;
        }
    }

    .services_projects {
        .service-box {
            border: 0 !important;
        }
    }


    .blog.style-2 {
        overflow: hidden;
        .card {
            padding: 10px;
            border: 1px solid #fff3 !important;
            h4 {
                font-size: 1rem !important;
            }
        }
        .mt-80{
            margin-top: 40px;
        }
    }

    .contact.style-2 {
        padding-bottom: 150px;
        h2 {
            letter-spacing: 1px;
        }
        .global_2 {
            bottom: -120px;
        }
        .mb-70{
            margin-bottom: 30px;
        }
        .mb-50{
            margin-bottom: 20px;
        }
    }

    footer.style-2 {
        .foot_links {
            text-align: initial !important;
            margin: 30px 0;
            display: flex;
            flex-wrap: wrap;
            a {
                width: 50%;
                margin: 10px 0 !important;
            }
        }
        .text-end,
        .text-center{
            text-align: initial !important;
        }
    }

    .navbar.style-3{
        position: relative !important;
        background-color: var(--color-blue2);
        padding: 20px 0;
        .navbar-collapse{
            margin-top: 15px;
        }
    }

    header.style-3 {
        padding: 0 0 30px;
        .main-img{
            display: none;
        }
        .content {
            .info{
                .h1{
                    font-size: 28px;
                    span:before{
                        width: 100px;
                        background-size: 100px;
                    }
                }
                .h5{
                    margin-top: 40px;
                }
            }
        }
    }

    .style-3 .testi_lines {
        width: calc(100% - 30px) !important;
    }

    .about.style-3{
        .img-left{
            position: relative;
            top: 0;
            max-width: 100%;
            margin: 50px 0;
            .info-circle{
                width: 150px;
                height: 150px;
                outline: 7px solid #fff;
                h2{
                    font-size: 35px;
                }
                small{
                    font-size: 10px;
                    text-transform: uppercase;
                }
                &:nth-of-type(2){
                    width: 120px;
                    height: 120px;
                    h2{
                        font-size: 30px;
                    }
                }
                &:nth-of-type(3){
                    width: 100px;
                    height: 100px;
                    h2{
                        font-size: 25px;
                    }
                }
                &:nth-of-type(4){
                    width: 110px;
                    height:110px;
                    h2{
                        font-size: 20px;
                    }
                }
            }
            
        }
        .info{
            .mb-70{
                margin-bottom: 30px !important;
            }
        }

        .btm-content{
            min-height: max-content;
            .mt-60{
                margin-top: 30px !important;
            }
        }
    }

    .about.style-3 .btm-content .img-right{
        display: none;
    }

    .about.style-3 .top-content{
        margin-bottom: 30px;
    }

    .pricing.style-3{
        .info{
            margin-bottom: 50px;
        }
        .text.mb-30{
            margin-bottom: 15px !important;
        }

        .mt-60{
            margin-top: 30px !important;
        }
    }

    .section-head.style-3 h3{
        font-size: 21px;
    }

    .blog.style-3 .card .img {
        height: 150px;
        margin-bottom: 5px;
    }

    .blog.style-3 .v_lines{
        display: none;
    }

    .blog.style-3 .client-logos {
        margin-top: 30px !important;
        .img {
            // text-align: center;
            margin: 20px 0;
        }
    }

    .chat-banner.style-3{
        .bttns {
            margin-top: 60px;
            text-align: start !important;
        }
    }

    footer.style-3{
        .items{
            margin-bottom: 50px;
            .title{
                margin-bottom: 20px;
            }
        }
        .foot{
            margin-top: 0;
            .logo{
                margin-bottom: 20px;
            }
        }
    }

    .fs-10px {
        font-size: 8px!important;
    }

    .navbar.style-4 .navbar-nav .nav-item .nav-link:before{
        display: none;
    }

    .navbar.style-4{
        background-color: #f0eff5;
        padding: 20px 0;
        .navbar-collapse{
            margin-top: 30px;
        }
    }

    .style-4{
        .mb-60 {
            margin-bottom: 30px!important;
        }
        .mb-70 {
            margin-bottom: 40px!important;
        }
        br{
            display: none;
        }
    }

    header.style-4 {
        overflow: hidden;
        .content {
            .info {
                margin-bottom: 50px;
                h1{
                    font-size: 25px;
                    line-height: 40px;
                    margin-bottom: 15px !important;
                }
                .title_small{
                    margin-bottom: 20px !important;
                }
                .mt-100{
                    margin-top: 50px !important;
                }
                .mt-50{
                    margin-top: 30px !important;
                }
                br{
                    display: none;
                }
                // .d-flex{
                //     flex-wrap: wrap;
                //     .btn{
                //         width: 100%;
                //         margin: 15px 0;
                //     }
                // }
                .small, small,
                .btn{
                    font-size: 10px;
                }
            }
        }
    }

    .clients.style-4{
        .client-logos{
            .img {
                margin: 20px 0;
                text-align: center;
            }
        }
    }

    .features.style-4 .content{
        flex-direction: column;
        .features-card{
            margin: 15px 0;
        }
    }

    .about.style-4 .content{
        padding: 40px 0;
    }


    .testimonials.style-4 {
        overflow: hidden;
        .content .testi-cards .client_card{
            right: 0 !important;
            flex-direction: column;
            margin: 20px 0 !important;
            .user_img{
                margin-bottom: 30px;
            }
            .inf_content{
                text-align: center;
                br{
                    display: none;
                }
            }
        }
    }

    .pricing.style-4 .content .row .col-lg-6:first-of-type .price-card {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        border: 1px solid #e9e8ee;
        margin-right: 0;
        margin-bottom: 30px;
    }
    .pricing.style-4 .content .row .col-lg-6:nth-of-type(2) .price-card {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
    }

    .faq.style-3.style-4 {
        overflow: hidden;
        .col-lg-6:nth-of-type(1){
            .accordion-item.border-0{
                border-bottom: 1px solid #9995 !important;
            }
        }
    }

    .community.style-4 .content,
    .community.style-5 .content{
        flex-direction: column;
        border-radius: 20px !important;
        .commun-card{
            width: 100%;
            justify-content: start;
            padding: 30px !important;
        }
    }

    .section-head.style-4 h2 {
        font-size: 25px;
    }

    footer.style-4 {
        margin-top: 120px;
        .d-flex{
            flex-direction: column;
            .btn{
                width: 100%;
                margin: 10px 0;
                &.me-4{
                    margin-right: 0 !important;
                }
            }
        }
        .foot .links{
            flex-wrap: wrap;
            margin: 30px 0;
            li{
                width: 50%;
                margin: 5px 0;
                a{
                    margin-left: 0;
                }
            }
        }
        .wave{
            left: 0;
            width: 100%;
        }
    }

    .screenshots.style-4 .screenshots-slider{
        width: 100%;
        left: 0;
    }
    .screenshots.style-4 .screenshots-slider .img{
        margin: 0 auto;
        width: 190px;
    }

    footer.style-4 .foot .logo{
        width: 160px;
    }

    .navbar.style-5{
        border-bottom: 1px solid #9999;
    }

    .navbar.style-5 .navbar-collapse{
        margin-top: 20px;
        padding: 15px 0;
        background: #fff9;
    }

    .navbar.style-5 .nav-item .nav-link{
        padding-left: 0;
    }

    .section-head.style-5 h2{
        font-size: 30px;
    }

    .about.style-5 .content .img{
        overflow: hidden;
        margin-bottom: 30px;
        padding-bottom: 50px;
    }

    .about.style-5 .content .img.main-img3 .img-body{
        max-height: 300px;
    }

    .download.style-5 .content h2 {
        font-size: 28px;
    }

    .download.style-5 .content h2 span {
        padding-bottom: 10px;
    }

    .download.style-5 .btn{
        margin: 10px 0;
    }

    .download.style-5 .btn.sm-butn span {
        font-size: 10px;
    }

    footer.style-5{
        .items{
            margin-bottom: 50px;
            .title{
                margin-bottom: 20px;
            }
        }
        .foot{
            margin-top: 0;
            .logo{
                margin-bottom: 20px;
            }
        }
    }
    
    .style-6{
        br{
            display: none;
        }
    }

    .navbar.style-6 .container-fluid {
        padding: 0 20px;
        .navbar-collapse{
            margin-top: 20px;
        }
    }

    header.style-6 .content{
        padding: 100px 0 20px;
    }

    header.style-6 .info h1,
    header.style-6 .info h1 span small{
        font-size: 30px;
        line-height: 1.5;
    }

    header.style-6 .info .text{
        margin-top: 20px;
        br{
            display: none;
        }
    }

    header.style-6 .info .form{
        border-radius: 15px;
        .form-group{
            width: 100%;
            margin: 8px 0;
        }
        .btn{
            width: 100%;
            margin-top: 15px;
        }
    } 

    .navbar.style-6 .nav-item .nav-link:after{
        display: inline-block !important;
    }

    header.style-6{
        padding: 0;
        .content{
            border-radius: 0;
        }
    }

    .section-head.style-6 h2,
    .section-head.style-6 h2 span small{
        font-size: 30px;
    }

    .about.style-6 {
        overflow: hidden;
        .info{
            margin-bottom: 30px;
        }
    }

    .service-card.style-6{
        margin: 15px;
    }
    
    .section-head.d-flex{
        display: block !important;
    }

    .projects.style-6{
        overflow: hidden;
        padding: 0;
        .content{
            border-radius: 0;
            .project-card.style-6{
                margin: 0;
            }
        }
    }

    .testimonials.style-6{
        overflow: hidden;
    }

    .choose-us.style-6{
        overflow: hidden;
        .info{
            padding: 0 15px;
            margin-top: 30px;
        }
    }

    .testimonials.style-6 .img{
        margin-top: 150px;
    }

    .clients.style-5 .content .img.img-card{
        width: 47%;
    }

    .number-card.style-6{
        margin: 15px 0;
        border: 0 !important;
    }

    .team.style-6{
        padding: 0;
        .content{
            border-radius: 0;
            padding: 70px 0;
            .text-end {
                text-align: left !important;
                margin-top: 10px;
            }
        }
    }

    .team-card.style-6 .img .social-icons a{
        transform: translateY(0) !important;
        opacity: 1;
    }

    .blog.style-6 .content{
        flex-wrap: wrap;
        .blog-card{
            width: 100% !important;
            margin-bottom: 30px;
            &:last-of-type{
                margin: 0 ;
            }
            &::after,
            &::before{
                display: none;
            }
        }
    }

    footer.style-6{
        padding-top: 40px;
        .items{
            margin-top: 30px;
            .title{
                margin-bottom: 15px;
            }
        }
        .foot{
            padding-top: 30px;
            line-height: 1.8;
            .logo{
                margin-bottom: 20px;
            }
        }
    }

    .blog-page.style-1 .popular-posts .border-end{
        border: 0 !important;
    }

    .about-page {
        header.style-5 {
            .info h1{
                font-size: 30px;
            }
            .main-vid{
                height: 350px;
            }
        }

        .choose-us.style-6 .info{
            padding: 30px 15px;
        }

        .clients .section-head{
            padding: 0 15px;
        }

        .culture {
            padding: 70px 15px;
        }
    }

    .contact.style-6 {
        .content .contact_message{
            right: 0;
            top: 30px;
        }
    }

    .contact-page.style-5 {
        .contact .content .contact_message{
            right: 0;
            top: 30px;
        }

        .section-head{
            margin-bottom: 50px !important;
        }

        .mb-100{
            margin-bottom: 70px !important;
        }

        .ltspc-20{
            letter-spacing: 3px !important;
            margin-bottom: 30px !important;
        }

        h4{
            font-size: 18px;
        }
    }

    .shop-page{
        .filter{
            margin-bottom: 30px;
        }

        .products-content .products.list-view .card-width{
            width: 100%;
        }
    }

    .product-page .related-products .related-products-slider .swiper-button-next,
     .product-page .related-products .related-products-slider .swiper-container-rtl .swiper-button-prev{
        right: 5px;
    }

    .product-page .related-products .related-products-slider .swiper-button-prev,
     .product-page .related-products .related-products-slider .swiper-container-rtl .swiper-button-next{
        left: 5px;
    }

    .product-page .product-slider{
        overflow: hidden;
    }

    .product-page .product-slider{
        margin-bottom: 50px;
    }

    .product-page .product-slider .gallery-top .swiper-slide .img,
    .product-page .product-slider .gallery-thumbs{
        height: 370px;
    }

    .product-page .product-details .nav-pills .nav-link{
        font-size: 18px;
    }

    .product-page .product-info .qyt-addCart .add-more{
        width: 25%;
    }
}

/* ======== */

@media screen and (max-width: 787px) {
    
}

/* ======= */

@media screen and (max-width: 520px) {

}
