.top-negative-5{
  top: -5 !important;
}


.swastha-features-container{
  margin: 40px 70px !important;
  background-color: #f1f2fa !important;
  border-radius: 20px !important;
}


.flex-center-solutions{    
  display: flex !important;
  align-content: center !important;
  justify-content: center !important;
  align-items: center !important;
}

.flex-center-solutions img{    
  height:200px !important;
  width: 200px !important;
}

.button-center{
  display: flex !important;
  align-content: center !important;
  justify-content: center !important;
  align-items: center !important;
}

.new-screen-shots{
  background-color: #FFFFFF !important;
}

.product-img-height{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.product-img-height img{
  height:300px !important;
}

.full-center{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.img-200{
  object-fit: contain !important;
  height:120px !important;
}

.portfolio-card{
  border: 1px solid #eef4f8;
}

.round-image-container{
  border-radius: 20px;
  overflow: hidden;
}

.brand-icon{
  font-size: 60px;
}

.floating-image img{
  object-fit: contain;
  height: 35%
}

.floating-image{
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-border{
  border-bottom: none !important
}

.border-bottom{
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}

.full-width-container{
  width: 100% !important;
  max-width: 100% !important;
}