


/* --------------- community style-4 --------------- */
.community.style-4{
    .content{
        border: 1px solid #9993;
        border-radius: 15px;
        padding: 30px 0;
        display: flex;
        .commun-card{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 33%;
            border-right: 1px solid #9993;
            padding: 20px 0;
            &:last-of-type{
                border: 0;
            }
            .icon{
                flex-shrink: 0;
                margin-right: 25px;
                i{
                    font-size: 60px;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-image: linear-gradient(to right,  #501e9c 0%,#8169f1 30%,#8169f1 30%,#a44cee 73%,#ff847f 100%);
                }
            }
            .inf{
                h5{
                    font-size: 23px;
                    color: #000;
                    font-weight: bold;
                    margin-bottom: 0;
                    line-height: 1.1;
                }
                p{
                    font-size: 12px;
                    color: #666;
                }
            }
        }
    }
}

/* --------------- community style-5 --------------- */
.community.style-5{
    .content{
        border: 1px solid #9993;
        border-radius: 15px;
        padding: 20px 0;
        display: flex;
        .commun-card{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 33%;
            border-right: 1px solid #9993;
            padding: 10px 0;
            &:last-of-type{
                border: 0;
            }
            .icon{
                flex-shrink: 0;
                margin-right: 25px;
                img{
                    filter: drop-shadow(-10px 10px 20px #084cba77);
                    -webkit-animation: scale_up_down 1s ease-in-out infinite alternate both;
	                        animation: scale_up_down 1s ease-in-out infinite alternate both;
                }
                
            }
            .inf{
                h5{
                    font-size: 20px;
                    color: #000;
                    font-weight: bold;
                    margin-bottom: 0;
                    line-height: 1.1;
                }
            }
        }
    }
}



